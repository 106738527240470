import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import fourzerofour from "../images/SlapstickFighter/404.png"
import Header from "../components/cybernate/header"
import { Container, Row, Col } from "reactstrap"
import "../css/fourzerofour.css"

const NotFoundPage = () => (
    <Layout header={Header} footerClassName="fourzerofour">
        <SEO title="404: Does not compute" />
        <Container fluid className="fourzerofour main-section">
            <Row className="justify-content-center">
                <Col>
                    <img
                        className="mx-auto d-block"
                        src={fourzerofour}
                        alt="404 not found"
                    />
                </Col>
            </Row>
        </Container>
    </Layout>
)

export default NotFoundPage
