import React from "react"
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    NavItem
} from "reactstrap"
import navLogo from "../../images/CybernateNavLogo.png"
import { Link } from "gatsby"

export default class Example extends React.Component {
    constructor(props) {
        super(props)

        this.toggle = this.toggle.bind(this)
        this.state = {
            isOpen: false,
        }
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen,
        })
    }
    render() {
        return (
            <div>
                <Navbar color="black" dark expand="md">
                 
                    <Link to="/" className="navbar-brand">
                        <img src={navLogo} alt="Cybernate Logo" />
                    </Link>

                    <NavbarToggler
                        onClick={this.toggle}
                        className="border-dark"
                    />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                            <NavItem className="mx-auto">
                                <Link to="/#games" className="nav-link">
                                    Games
                                </Link>
                            </NavItem>
                            <NavItem className="mx-auto">
                                <Link to="/#contact" className="nav-link">
                                    Contact
                                </Link>
                            </NavItem>
                        </Nav>
                    </Collapse>
                </Navbar>
            </div>
        )
    }
}
